var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "animated fadeIn w-100"
  }, [_c('b-card', [_c('b-card-body', [_c('b-row', [_c('b-col', [_c('filtering-panel', {
    ref: "filteringPanel",
    attrs: {
      "mode": "server",
      "dataset-name": "reports-map-".concat(_vm.mode),
      "load-dictionaries": _vm.loadDictionaries,
      "loaded": _vm.filteringPanel.loaded,
      "show-labels": false,
      "hide-custom-filters": true,
      "filters": _vm.filteringPanel.filters
    },
    on: {
      "dict-loaded": _vm.onFilteringPanelLoad,
      "search": _vm.getData,
      "change": _vm.filterData,
      "custom-filter-selected": _vm.onCustomFilterSelected,
      "state-changed": _vm.onFilteringPanelStateChange
    }
  })], 1)], 1), _vm.isLoading ? _c('b-row', [_c('b-col', [_c('div', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    attrs: {
      "variant": "light"
    }
  })], 1)])], 1) : _vm._e(), !_vm.isLoading ? _c('b-row', {
    attrs: {
      "no-glutters": ""
    }
  }, [_c('b-col', [!_vm.isLoading && _vm.reportsMap.categories ? _c('b-row', {
    staticClass: "header"
  }, [_c('b-col', {
    staticClass: "col-bordered",
    attrs: {
      "lg": "2"
    }
  }, [_vm._v(" Report Name ")]), _c('b-col', {
    staticClass: "col-bordered",
    attrs: {
      "lg": "1"
    }
  }, [_vm._v(" Responsibles ")]), _c('b-col', {
    staticClass: "col-bordered",
    attrs: {
      "lg": "1"
    }
  }, [_vm._v(" Reviewers ")]), _c('b-col', {
    staticClass: "col-bordered",
    attrs: {
      "lg": "8"
    }
  }, [_vm._v(" Reports ")])], 1) : _vm._e(), !_vm.isLoading ? _vm._l(_vm.reportsMap.categories, function (category, index) {
    return _c('b-row', {
      key: "cat-".concat(index)
    }, [_c('b-col', {
      staticClass: "col-bordered",
      attrs: {
        "lg": "2"
      }
    }, [_c('strong', [_vm._v(" " + _vm._s(category.cat_name) + " ")])]), _c('b-col', {
      staticClass: "col-bordered",
      attrs: {
        "lg": "1"
      }
    }, [_vm._v(" " + _vm._s(category.responsible_names) + " ")]), _c('b-col', {
      staticClass: "col-bordered",
      attrs: {
        "lg": "1"
      }
    }, [_vm._v(" " + _vm._s(category.reviewer_names) + " ")]), _c('b-col', {
      staticClass: "col-bordered",
      attrs: {
        "lg": "8"
      }
    }, _vm._l(category.reports, function (report, index2) {
      return _c('b-button', {
        key: "rep-".concat(index2),
        staticClass: "report-cell",
        attrs: {
          "size": "sm",
          "squared": "",
          "variant": report.color
        },
        on: {
          "click": function click($event) {
            report.submitted ? report.open(report) : report.create(report);
          }
        }
      }, [_vm._v(" " + _vm._s(report.periodTitle) + " ")]);
    }), 1)], 1);
  }) : _vm._e()], 2)], 1) : _vm._e(), _c('b-modal', {
    ref: "preview-report-modal",
    attrs: {
      "centered": "",
      "hide-footer": "",
      "size": !_vm.$isMobile ? 'lg' : '',
      "title": _vm.previewModal.title
    }
  }, [_c('b-card', [_c('b-card-body', [_c('div', {
    staticClass: "preview-report",
    domProps: {
      "innerHTML": _vm._s(_vm.previewModal.htmlContent)
    }
  })])], 1), _c('hr'), _c('div', {
    staticClass: "form-row d-flex justify-content-end"
  }, [_vm.previewModal.isResponsible && !_vm.previewModal.Reviewed && _vm.previewModal.isDailyProduction ? _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.viewReport(_vm.previewModal.ID);
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "plus"
    }
  }), _vm._v(" Append report ")], 1) : _vm._e(), _vm.previewModal.isReviewer && !_vm.previewModal.Reviewed ? _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "warning"
    },
    on: {
      "click": function click($event) {
        return _vm.setReportReviewed();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "signature"
    }
  }), _vm._v(" Mark as Reviewed ")], 1) : _vm._e(), _c('b-button', {
    staticClass: "m-1",
    attrs: {
      "variant": "outline-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.closeModal();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "times"
    }
  }), _vm._v(" Close ")], 1)], 1)], 1)], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }