<template>
  <div class="animated fadeIn w-100">
    <b-card>
      <b-card-body>
        <b-row>
          <b-col>
            <filtering-panel
              ref="filteringPanel"
              mode="server"
              :dataset-name="`reports-map-${mode}`"
              :load-dictionaries="loadDictionaries"
              :loaded="filteringPanel.loaded"
              :show-labels="false"
              :hide-custom-filters="true"
              :filters="filteringPanel.filters"
              @dict-loaded="onFilteringPanelLoad"
              @search="getData"
              @change="filterData"
              @custom-filter-selected="onCustomFilterSelected"
              @state-changed="onFilteringPanelStateChange"
            />
          </b-col>
        </b-row>
        <!--
        <b-overlay
          :show="isLoading"
          :opacity="0.5"
          spinner-variant="secondary"
          rounded="sm"
        >-->

        <b-row v-if="isLoading">
          <b-col>
            <div class="text-center">
              <b-spinner variant="light" />
            </div>
          </b-col>
        </b-row>
        <b-row v-if="!isLoading" no-glutters>
          <b-col>
            <b-row v-if="!isLoading && reportsMap.categories" class="header">
              <b-col lg="2" class="col-bordered">
                Report Name
              </b-col>
              <b-col lg="1" class="col-bordered">
                Responsibles
              </b-col>
              <b-col lg="1" class="col-bordered">
                Reviewers
              </b-col>

              <b-col lg="8" class="col-bordered">
                Reports
              </b-col>
            </b-row>
            <template v-if="!isLoading">
              <b-row
                v-for="(category, index) in reportsMap.categories"
                :key="`cat-${index}`"
              >
                <b-col lg="2" class="col-bordered">
                  <strong> {{ category.cat_name }} </strong>
                </b-col>
                <b-col lg="1" class="col-bordered">
                  {{ category.responsible_names }}
                </b-col>
                <b-col lg="1" class="col-bordered">
                  {{ category.reviewer_names }}
                </b-col>

                <b-col lg="8" class="col-bordered">
                  <b-button
                    size="sm"
                    class="report-cell"
                    squared
                    v-for="(report, index2) in category.reports"
                    :key="`rep-${index2}`"
                    :variant="report.color"
                    @click="
                      report.submitted
                        ? report.open(report)
                        : report.create(report)
                    "
                  >
                    {{ report.periodTitle }}
                  </b-button>
                </b-col>
              </b-row>
            </template>
          </b-col>
        </b-row>

        <b-modal
          ref="preview-report-modal"
          centered
          hide-footer
          :size="!$isMobile ? 'lg' : ''"
          :title="previewModal.title"
        >
          <b-card>
            <b-card-body>
              <div class="preview-report" v-html="previewModal.htmlContent" />
            </b-card-body>
          </b-card>
          <hr />
          <div class="form-row d-flex  justify-content-end">
            <b-button
              v-if="
                previewModal.isResponsible &&
                  !previewModal.Reviewed &&
                  previewModal.isDailyProduction
              "
              variant="outline-dark"
              class="m-1"
              @click="viewReport(previewModal.ID)"
            >
              <font-awesome-icon icon="plus" /> Append report
            </b-button>

            <b-button
              v-if="previewModal.isReviewer && !previewModal.Reviewed"
              variant="warning"
              class="m-1"
              @click="setReportReviewed()"
            >
              <font-awesome-icon icon="signature" /> Mark as Reviewed
            </b-button>

            <b-button variant="outline-dark" class="m-1" @click="closeModal()">
              <font-awesome-icon icon="times" /> Close
            </b-button>
          </div>
        </b-modal>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import moment from "moment";

import FilteringPanel from "@/components/FilteringPanel";

import { mapState, mapActions } from "vuex";

export default {
  name: "ReportsMap",
  props: {
    mode: {
      type: String,
      default: ""
    }
  },
  components: {
    FilteringPanel
  },
  data: function() {
    return {
      isLoading: false,
      filteringPanel: {
        loaded: false,
        selected: {},
        filters: [
          {
            type: "select",
            title: "Department",
            name: "department",
            trackby: "id",
            label: "label",
            options: [],
            dataType: "string",
            selected: {}
          },
          {
            type: "select",
            title: "Report Name",
            name: "report-category",
            trackby: "id",
            label: "label",
            options: [],
            dataType: "string",
            selected: {}
          },
          {
            type: "select",
            title: "Frequency",
            name: "frequency",
            trackby: "id",
            label: "label",
            options: [],
            dataType: "string",
            selected: {}
          },

          {
            type: "select",
            title: "Responsible",
            name: "responsible",
            trackby: "id",
            label: "label",
            options: [],
            dataType: "string",
            selected: {}
          },
          {
            type: "select",
            title: "Reviewer",
            name: "reviewer",
            trackby: "id",
            label: "label",
            options: [],
            dataType: "string",
            selected: {}
          },
          {
            type: "select",
            dataType: "string",
            title: "Status",
            tooltip: "Report status",
            name: "status",
            trackby: "id",
            label: "label",
            multiple: true,
            options: [
              { id: 0, label: "Active" },
              { id: 1, label: "Deleted" }
            ],
            selected: {}
          },
          {
            type: "daterange",
            title: "Report date",
            name: "period",
            dataType: "datetime"
          }
        ]
      },
      categoriesMapped: [],
      submittedReports: [],
      previewModal: {
        ID: "",
        title: "",
        htmlContent: "",
        isReviewer: false,
        Reviewed: false
      },
      reportsMap: {}
    };
  },
  computed: {
    ...mapState({
      profile: state => state.profile
    })
  },
  mounted() {
    this.filteringPanel.selected = this.$refs.filteringPanel.selected;

    if (this.mode === "submission") {
      this.filteringPanel.filters = this.filteringPanel.filters.filter(
        i => !["responsible", "reviewer"].includes(i.name)
      );
    }
  },
  methods: {
    ...mapActions("profile", ["fetchProfile"]),
    async loadDictionaries() {
      let self = this;

      const users = async () => {
        let self = this;

        if (this.mode !== "submission") {
          this.$api.get("users").then(response => {
            self.filteringPanel.filters.find(
              f => f.name === "responsible"
            ).options = response.map(u => ({
              id: u.id,
              label: u.full_name
            }));

            self.filteringPanel.filters.find(
              f => f.name === "reviewer"
            ).options = response.map(u => ({
              id: u.id,
              label: u.full_name
            }));

            this.responsibles = [
              ...response.map(u => ({
                id: u.id,
                name: u.full_name
              }))
            ];
          });
        }
      };

      const categories = () => {
        this.$api.get("users/reports/categories/mapped").then(response => {
          self.categoriesMapped = response;

          self.filteringPanel.filters.find(
            f => f.name === "department"
          ).options = self.$helpers.getDistinctArray(
            response,
            "dep_id",
            "dep_name",
            "id",
            "label"
          );

          //self.updateReportCategories() ???
        });
      };

      const frequencies = () => {
        this.$api.get("users/reports/frequencies").then(response => {
          self.filteringPanel.filters.find(
            f => f.name === "frequency"
          ).options = response.map(u => ({
            id: u.id,
            label: u.name
          }));
        });
      };

      Promise.all([users(), categories(), frequencies()]).then(
        () => (this.filteringPanel.loaded = true)
      );
    },
    onCustomFilterSelected() {},
    onFilteringPanelStateChange() {},
    onFilteringPanelLoad() {
      let self = this;

      this.getData().then(() => {
        self.buildReportsMap();
      });
    },
    search() {
      let self = this;

      this.getData().then(() => {
        self.buildReportsMap();
      });
    },
    async getData() {
      /*
      let self = this;

      return new Promise(async function(fulfill) {
        let filter = self.$refs.filteringPanel.selected;

        self.isLoading = true;

        let url = "";

        if (self.mode === "overview") url = "users/reports/map/overview";
        if (self.mode === "submission") url = "users/reports/map/submission";
        if (self.mode === "review") url = "users/reports/map/review";

        self.submittedReports = await self.$api.post(url, {
          filter: filter
        });

        self.isLoading = false;

        fulfill(true);
      });*/
      let self = this;

      let filter = self.$refs.filteringPanel.selected;

      self.isLoading = true;

      let url = "";

      if (self.mode === "overview") url = "users/reports/map/overview";
      if (self.mode === "submission") url = "users/reports/map/submission";
      if (self.mode === "review") url = "users/reports/map/review";

      self.submittedReports = await self.$api.post(url, {
        filter: filter
      });

      self.isLoading = false;
    },
    filterData: async function(e) {
      this.updateReportCategories();

      if (this.submittedReports.length === 0) return;

      //console.log('filterData')

      this.filteringPanel.selected = e;

      if (e.period.changed) {
        await this.getData();

        e.period.changed = false;
      }

      this.buildReportsMap();
    },

    buildReportsMap() {
      //console.log('buildReportsMap')

      if (this.submittedReports.length === 0) return;

      let filteredData = this.categoriesMapped;

      filteredData = filteredData.filter(i =>
        this.filteringPanel.selected.department
          ? i.dep_id === this.filteringPanel.selected.department.id
          : true
      );
      filteredData = filteredData.filter(i =>
        this.filteringPanel.selected["report-category"]
          ? i.cat_id === this.filteringPanel.selected["report-category"].id
          : true
      );
      filteredData = filteredData.filter(i =>
        this.filteringPanel.selected.frequency
          ? i.frequency === this.filteringPanel.selected.frequency.id
          : true
      );

      if (this.mode === "submission")
        filteredData = filteredData.filter(i =>
          i.responsible_ids.split(",").includes(this.profile.data.id.toString())
        ); ///current user

      filteredData = filteredData.filter(i =>
        this.filteringPanel.selected.responsible
          ? i.responsible_ids
              .split(",")
              .includes(this.filteringPanel.selected.responsible.id)
          : true
      );

      if (this.mode === "review")
        filteredData = filteredData.filter(i =>
          i.reviewer_ids.split(",").includes(this.profile.data.id.toString())
        ); ///current user

      filteredData = filteredData.filter(i =>
        this.filteringPanel.selected.reviewer
          ? i.reviewer_ids
              .split(",")
              .includes(this.filteringPanel.selected.reviewer.id)
          : true
      );

      let selectedStatuses = this.filteringPanel.selected.status
        ? this.filteringPanel.selected.status.map(s => s.label)
        : [];

      filteredData = filteredData.filter(i =>
        selectedStatuses.length && i["Status"]
          ? selectedStatuses.includes(i["Status"])
          : true
      );

      this.reportsMap.categories = filteredData; //this.$helpers.getDistinctArray(filteredData, 'cat_id', 'cat_name', 'id', 'name')

      for (let category of this.reportsMap.categories) {
        category.reports = this.getCategoryReports(category.cat_id);
      }

      this.$forceUpdate();
    },

    getCategoryReports(cat_id) {
      let category = this.categoriesMapped.find(c => c.cat_id === cat_id);

      let reports = [];

      let period = {
        from: "",
        to: ""
      };

      let title = "";

      let startDate = this.filteringPanel.selected.period.startDate;
      let endDate = this.filteringPanel.selected.period.endDate;

      if (moment.utc(endDate) > moment.utc())
        endDate = moment
          .utc()
          .add(1, "day")
          .format("YYYY-MM-DD");
      //if (moment.utc(endDate) > moment.utc()) endDate = moment.utc().format('YYYY-MM-DD')

      let date = moment.utc(startDate);

      //2016-11-07 it is a start of bi-weekly period
      let biWeeklyDatesArray = this.$helpers.getBiWeeklyDatesArray(
        process.env.VUE_APP_BI_WEEKLY_START,
        endDate,
        false
      );

      let i = 0;

      while (!period.to || period.to <= endDate) {
        if (category.frequency === this.$constants.REPORTS_FREQUENCY.DAILY) {
          title = moment(date).format("YYYY-MM-DD");
          period.from = moment(date).format("YYYY-MM-DD");
          period.to = moment(date)
            .add(1, "day")
            .format("YYYY-MM-DD");

          date = date.add(1, "day");
        }

        if (category.frequency === this.$constants.REPORTS_FREQUENCY.WEEKLY) {
          title = "Week " + moment.utc(date).isoWeek();
          period.from = moment
            .utc(date)
            .startOf("isoWeek")
            .format("YYYY-MM-DD");
          period.to = moment
            .utc(period.from)
            .endOf("isoWeek")
            .format("YYYY-MM-DD");
          date = moment
            .utc(date)
            .startOf("isoWeek")
            .add(1, "week");
        }

        if (category.frequency === this.$constants.REPORTS_FREQUENCY.BIWEEKLY) {
          title =
            "Weeks " +
            moment(biWeeklyDatesArray[i].startDate).isoWeek() +
            "&" +
            moment(biWeeklyDatesArray[i].endDate).isoWeek();
          period.from = biWeeklyDatesArray[i].startDate;
          period.to = biWeeklyDatesArray[i].endDate;
        }

        if (category.frequency === this.$constants.REPORTS_FREQUENCY.MONTHLY) {
          title = moment(date).format("MMMM");
          period.from = moment(date)
            .startOf("month")
            .format("YYYY-MM-DD");
          period.to = moment(period.from)
            .endOf("month")
            .format("YYYY-MM-DD");
          date = date.add(1, "month");
        }

        if (
          category.frequency === this.$constants.REPORTS_FREQUENCY.QUARTERLY
        ) {
          title = "Quarter " + moment(date).quarter();
          period.from = moment(date)
            .startOf("quarter")
            .format("YYYY-MM-DD");
          period.to = moment(period.from)
            .endOf("quarter")
            .format("YYYY-MM-DD");
          date = date.add(1, "quarter");
        }
        if (category.frequency === this.$constants.REPORTS_FREQUENCY.YEARLY) {
          let dd = date.clone().subtract(1, "year");

          title = "Year " + moment(dd).year();

          period.from = moment(dd)
            .startOf("year")
            .format("YYYY-MM-DD");
          period.to = moment(period.from)
            .endOf("year")
            .format("YYYY-MM-DD");

          date = date.add(1, "year");
        }

        let submitted = this.submittedReports.find(
          p =>
            p.From >= period.from &&
            p.To <= period.to &&
            p.category_id === category.cat_id
        );

        let color = "outline-dark";

        if (submitted) {
          color = "success";
          if (submitted.Reviewed === "Yes") color = "primary";
        }

        let report = {
          //submitted: Math.random() >= 0.5,
          color: color,
          category: category,
          submitted: submitted,
          periodTitle: title,
          period: {
            ...period
          },

          open: report => {
            this.showPreviewModal(report.submitted);
          },

          create: report => {
            let s = {
              department: {
                id: report.category.dep_id,
                label: report.category.dep_name
              },
              category: {
                id: report.category.cat_id,
                label: report.category.cat_name,
                frequency: report.category.frequency
              },
              period: {
                startDate: report.period.from,
                endDate: report.period.to
              }
            };

            this.$store.dispatch("userReports/saveState", s);

            this.$router.push({
              name: "Report submission",
              params: {
                action: "create"
              }
            });
          }
        };

        i++;

        if (
          category.frequency === this.$constants.REPORTS_FREQUENCY.BIWEEKLY &&
          i >= biWeeklyDatesArray.length
        ) {
          break;
        }

        //20200107
        //if (period.from < startDate) continue

        //20200107
        if (category.frequency !== this.$constants.REPORTS_FREQUENCY.YEARLY) {
          if (period.from < startDate) continue;

          //not exact interval match
          if (period.from > endDate) break;
        } else {
          if (moment(period.from).year() >= moment().year()) continue;
        }

        reports.push(report);
      }

      return reports;
    },
    setReportReviewed() {
      let self = this;

      this.$api
        .put(`users/reports/${this.previewModal.ID}/reviewed`)
        .then(response => {
          self.previewModal.Reviewed = true;

          //self.drawReportsTable()

          self.$form.msgBoxOk(response.message);
        })
        .catch(error => {
          self.$form.msgBoxOk(error.message);
        });
    },
    closeModal: function() {
      this.$refs["preview-report-modal"].hide();
    },
    showPreviewModal(report) {
      if (!report) return;

      this.previewModal.ID = report["ID"];
      this.previewModal.title =
        "#" +
        report["ID"] +
        " " +
        report["Report Name"] +
        " ( " +
        report["Report Date"] +
        " )";
      this.previewModal.htmlContent = report["Content"];
      this.previewModal.Reviewed = report["Reviewed"] === "Yes";

      let rev = report["reviewers_id"] ? report["reviewers_id"].split(",") : [];
      this.previewModal.isReviewer = rev.includes(
        this.profile.data.id.toString()
      );

      let res = report["responsible_ids"]
        ? report["responsible_ids"].split(",")
        : [];
      this.previewModal.isResponsible = res.includes(
        this.profile.data.id.toString()
      );

      this.previewModal.isDailyProduction =
        report["Report Name"] === "Daily Production Report";

      this.$refs["preview-report-modal"].show();
    },

    addReport: function() {
      this.$router.push({
        name: "Report submission",
        params: {
          action: "create"
        }
      });
    },
    viewReport: function(id) {
      this.$router.push({
        name: "Report submission",
        params: {
          action: "view",
          id: id
        }
      });
    },

    viewContent(id) {
      let report = this.reportTable.dataSet.find(item => item.ID === id);

      if (!report) return;

      this.previewModal.ID = report["ID"];
      this.previewModal.title = report["Report Name"];
      this.previewModal.htmlContent = report["Content"];
      this.previewModal.Reviewed = report["Reviewed"] === "Yes";
      let rev = report["reviewers_id"] ? report["reviewers_id"].split(",") : [];
      this.previewModal.isReviewer = rev.includes(
        this.profile.data.id.toString()
      );
      this.$refs["preview-report-modal"].show();
    },
    updateReportCategories() {
      if (!this.filteringPanel.selected.department) return;

      this.filteringPanel.filters.find(
        f => f.name === "report-category"
      ).options = this.$helpers.getDistinctArray(
        this.categoriesMapped.filter(
          s => s.dep_id === this.filteringPanel.selected.department.id
        ),
        "cat_id",
        "cat_name",
        "id",
        "label"
      );
    }
  },
  watch: {
    /*
        'filteringPanel.selected.department': function (newVal, oldVal) {

            if (newVal && newVal.id && JSON.stringify(newVal) !== JSON.stringify(oldVal)) {

                this.filteringPanel.filters.find(f => f.name === 'category').options = this.$helpers.getDistinctArray(this.categoriesMapped.filter(s => s.dep_id === newVal.id), 'cat_id', 'cat_name', 'id', 'label')

            }
        },
        */
  }
};
</script>

<style>
.header {
  text-align: center;
  font-weight: bold;
  border-top: 1px solid #e8e8e8;
}

.header div {
  padding: 10px;
}

.row-bordered {
  border-bottom: 1px solid #e8e8e8;
  margin: 0 3px;
  padding-top: 5px;
  padding-bottom: 5px;
}

.col-bordered {
  padding-top: 5px;
  padding-bottom: 5px;
  border-right: 1px solid #e8e8e8;
  border-bottom: 1px solid #e8e8e8;
}

.report-cell {
  width: 10em;
  margin: 2px;
  border-color: #c8ced3;
}

.fa-toolbar {
  color: #0000008a;
}

.preview-report {
  height: 500px;
  width: 100%;
  overflow-y: scroll;
}
</style>
